<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        问卷调查
      </template>
      <template #input>
        <!-- <span class="tag">标签名称：</span> -->
        <a-input placeholder="请输入姓名" v-model="queryParams.name" />
        <a-input placeholder="请输入手机号" v-model="queryParams.mobile" />
        <a-select style="width: 300px" allowClear v-model="queryParams.courseId" placeholder="培训课程">
          <a-select-option
            :value="item.courseId"
            v-for="item in courseData"
            :key="item.courseId"
          >
            {{ item.courseName }}
          </a-select-option>
        </a-select>
        <a-button type="primary" class="btn" @click="search()">搜索</a-button>
        <a-button class="btn" style="margin-left: 20px;" @click="exportFile">导出</a-button>
      </template>
    </HeaderBox>

    <!-- 表格数据 -->
    <a-table
      class="table-template"
      :rowKey="(item,index) => index"
      :columns="columns"
      :data-source="tableData"
      :scroll="{ x: 1700 }"
      :loading="loading"
      @change="onPage"
      :pagination="{
        total: total,
        current: queryParams.current,
        defaultPageSize: queryParams.size,
        showSizeChanger: true,
        showTotal: function (total, range) {
          return `共${total}条`;
        },
      }"
    >
      <template slot="index" slot-scope="item, row, i">
        <div style="text-align: center;">
          {{ (queryParams.current - 1) * 10 + i + 1 }}
        </div>
      </template>
      <template slot="operation" slot-scope="item">
        <div class="btn_router_link">
          <a @click="getDetail(item)">详情</a>
        </div>
      </template>
    </a-table>

    <!-- 详情弹框 -->
    <a-modal
      v-model="detailShow"
      align="center"
      title="问卷调查"
      :footer="null"
      width="800px"
    >
      <div class="userMess">
        <p>姓名：{{ detailData.name }}</p>
        <p>手机号：{{ detailData.mobile }}</p>
        <p>工作单位：{{ detailData.work }}</p>
      </div>
      <a-table
        class="table-template"
        style="margin: 0"
        :rowKey="(item,index) => index"
        :columns="columnsDetail"
        :data-source="detailData.list"
        :pagination="false"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ i + 1 }}
          </div>
        </template>
        <template slot="keyname" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ row.titleList?row.titleList[i].title:item }}
          </div>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import HeaderBox from '@/components/HeaderBox.vue'
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    fixed: "left",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "姓名",
    width: "90px",
    fixed: "left",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "手机号",
    width: "130px",
    fixed: "left",
    align: "center",
    dataIndex: "mobile",
  },
  {
    title: "工作单位",
    width: "150px",
    fixed: "left",
    align: "center",
    dataIndex: "work",
  },
  {
    title: "培训课程",
    width: "130px",
    align: "center",
    dataIndex: "courseName",
  },
  {
    title: "问题",
    align: "center"
  },
  {
    title: "操作",
    align:'center',
    width: "160px",
    scopedSlots: { customRender: "operation" }
  }
];

const columnsDetail = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "问题",
    align: "center",
    dataIndex: "keyname",
    scopedSlots: { customRender: "keyname" },
  },
  {
    title: "反馈",
    align: "center",
    dataIndex: "value",
  },
];

export default {
  components: { HeaderBox },
  data() {
    return {
      columns,
      defaultColumns: null,
      columnsDetail,
      loading: false,
      tableData: [],
      total: 0,
      queryParams: {
        current: 1, // 页码
        size: 10, // 页数
        courseId: undefined, // 课程id
        name: '',
        mobile: ''
      },

      courseData: [], // 课程下拉列表
      detailShow: false,
      detailData: {}, // 详情信息
    }
  },
  methods: {
    // 搜索
    search() {
      this.queryParams.current = 1;
      this.getManageList();
    },
    // 分页
    onPage(e) {
      this.queryParams.current = e.current;
      this.queryParams.size = e.pageSize;
      this.getManageList();
    },
    // 查询列表
    getManageList() {
      this.loading = true;
      this.$ajax({
        method: "get",
        url: "/hxclass-management/survey/answer/list",
        params: this.queryParams,
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          /**
           * 处理动态表头 -- start
           */
          let maxArr = [];
          res.data.records.map(item => {
            let arr = [];
            for(let key in item) {
              if(key.indexOf('answer') != -1 && item[key]) {
                arr.push(key)
              }
            }
            if(maxArr.length < arr.length) {
              maxArr = arr;
            }
          })

          let arr = maxArr.map((item,index)=>{
            return {
              title: "问题" + (index + 1),
              align: "center",
              dataIndex: item
            }
          })
          arr.push({
            title: '建议',
            align: "center",
            dataIndex: 'advice',
            width: 300
          },{
            title: '提交时间',
            align: "center",
            dataIndex: 'createTime'
          })
          this.columns = JSON.parse(JSON.stringify(this.defaultColumns))
          this.columns.splice(5,1,...arr)
          /**
           * 处理动态表头 -- end
           */

          this.tableData = res.data.records;
          this.total = res.data.total;
        } else {
          this.tableData = [];
          this.total = 0;
        }
      });
    },
    // 查询课程列表---下拉框
    getCourseList(){
      this.$ajax({
        method: 'get',
        url:"/hxclass-management/course/select",
      }).then(res => {
        if (res.code == 200 && res.success) {
          this.courseData = res.data
        }
      })
    },
    // 详情
    getDetail(e) {
      this.$ajax({
        method: "get",
        url: "/hxclass-management/survey/answer/detail",
        params: {
          id: e.id
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          res.data.list = []
          for(let key in res.data) {
            if(key.indexOf('answer') != -1 && res.data[key]) {
              res.data.list.push({
                keyname: '问题' + (res.data.list.length + 1),
                titleList: res.data.titleList,
                value: res.data[key]
              })
            }
          }
          res.data.list.push({
            keyname: '建议',
            value: res.data.advice
          })
          this.detailData = res.data;
          this.detailShow = true;
        } else {
          this.detailData = {};
        }
      });
    },
    // 导出
    exportFile() {
      
      window.open(
        this.$config.target + 
        "/hxclass-management/survey/answer/export?courseId=" +
        encodeURIComponent(this.queryParams.courseId || '') +
        "&name=" +
        encodeURIComponent(this.queryParams.name || '') +
        "&mobile=" +
        encodeURIComponent(this.queryParams.mobile || '')
      );
    }
  },
  created() {
    this.defaultColumns = JSON.parse(JSON.stringify(columns))
    this.getManageList();
    this.getCourseList();
  } 
}
</script>

<style scoped lang="less">
  .userMess{
    display: flex;
    margin-bottom: 15px;
    flex-wrap: wrap;
    p{
      margin-right: 20px;
    }
    p:last-child{
      margin-right: 0;
    }
  }
</style>